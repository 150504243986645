var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{attrs:{"title":"Ajouter machine vendue"}},[_c('b-card-text',[_c('span',[_vm._v("Coordonnées du machine")])]),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nom de machine","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"nom"},model:{value:(_vm.machine.name),callback:function ($$v) {_vm.$set(_vm.machine, "name", $$v)},expression:"machine.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Numéro de série","label-for":"reference"}},[_c('validation-provider',{attrs:{"name":"reference"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"reference","placeholder":"Numéro de série","state":errors.length > 0 ? false : null},model:{value:(_vm.machine.reference),callback:function ($$v) {_vm.$set(_vm.machine, "reference", $$v)},expression:"machine.reference"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Prix de vente","label-for":"Prix de vente"}},[_c('validation-provider',{attrs:{"name":"sales_price"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Prix de vente"},model:{value:(_vm.machine.sales_price),callback:function ($$v) {_vm.$set(_vm.machine, "sales_price", $$v)},expression:"machine.sales_price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Date de vente","label-for":"date"}},[_c('validation-provider',{attrs:{"name":"date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control invoice-edit-input",attrs:{"placeholder":"Date de vente","state":errors.length > 0 ? false : null},model:{value:(_vm.machine.date),callback:function ($$v) {_vm.$set(_vm.machine, "date", $$v)},expression:"machine.date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Client","label-for":"customer"}},[_c('validation-provider',{attrs:{"name":"customer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.customers,"label":"company_name","input-id":"invoice-data-client","clearable":false,"placeholder":"Choisir un client"},model:{value:(_vm.machine.customer),callback:function ($$v) {_vm.$set(_vm.machine, "customer", $$v)},expression:"machine.customer"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.addMachine.apply(null, arguments)}}},[_vm._v(" Enregistrer ")]),_c('b-button',{attrs:{"variant":"outline-secondary","type":"reset","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'}},[_vm._v(" Réinitialiser ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }