<template>
  <b-card-code title="Ajouter machine vendue">
    <b-card-text>
      <span>Coordonnées du machine</span>
    </b-card-text>

    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Nom de machine"
              label-for="name"
            >
              <validation-provider
                #default="{ errors }"
                name="name"
              >
                <b-form-input
                  v-model="machine.name"
                  :state="errors.length > 0 ? false : null"
                  placeholder="nom"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Numéro de série"
              label-for="reference"
            >
              <validation-provider
                #default="{ errors }"
                name="reference"
              >
                <b-form-input
                  id="reference"
                  v-model="machine.reference"
                  placeholder="Numéro de série"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Prix de vente"
              label-for="Prix de vente"
            >
              <validation-provider
                #default="{ errors }"
                name="sales_price"
              >
                <b-form-input
                  v-model="machine.sales_price"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Prix de vente"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
              label="Date de vente"
              label-for="date"
            >
              <validation-provider
                #default="{ errors }"
                name="date"
              >
                  <flat-pickr
                      v-model="machine.date"
                      placeholder="Date de vente"
                      :state="errors.length > 0 ? false : null"
                      class="form-control invoice-edit-input"
                    />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Client"
              label-for="customer"
            >
              <validation-provider
                #default="{ errors }"
                name="customer"
              >
                  <v-select
                    v-model="machine.customer"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="customers"
                    label="company_name"
                    input-id="invoice-data-client"
                    :clearable="false"
                    placeholder="Choisir un client"
                  />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

        
          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click.prevent="addMachine"
            >
              Enregistrer
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              Réinitialiser
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'
import axiosIns from '@/libs/axios'
import { mapGetters, mapActions } from 'vuex'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    BCardText,
    /* BFormTextarea, */
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      machine: {
        name: '',
        reference: '',
        date: '',
        customer: '',
        sales_price: '',
      },
      customers: [],
      required,
    }
  },
  
  created() {
    this.getCustomers()
  },
  methods: {
    async getCustomers() {
      const { data } = await axiosIns.get('/customers/')
      this.customers = data
    },
    async addMachine() {
      try {
        this.machine.customer=this.machine.customer.id
        await axiosIns.post('/machines/create/', this.machine)
        setTimeout(() => {
          this.showToast('success', 'top-center', 'Machine ajouté avec succès')
        }, 1000)
        this.$router.push('/machines/')
      } catch (error) {
        setTimeout(() => {
          this.showToast('danger', 'top-center', error.toString())
        }, 1000)
      }
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
